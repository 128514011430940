import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/auth/LoginView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LoginView,
    meta: { authOnly: false }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { authOnly: false }
  },

  {
    path: "/otp",
    name: "otp",
    component: () =>
      import(/* webpackChunkName: "otp" */ "../views/auth/OTPVerification.vue"),
    // meta: { authOnly: false }
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/DashboardView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/withdraw-history",
    name: "WithdrawHistory",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/WithdrawHistory.vue"
      ),
    meta: { authOnly: true },
  },

  {
    path: "/deposit-history",
    name: "DepositHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/DepositHistory.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/user-history",
    name: "UserHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UserHistory.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/fund-history",
    name: "FundTransferHistory",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/FundTransferHistory.vue"
      ),
    meta: { authOnly: true },
  },

  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/SecurityView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/ProfileView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/assets",
    name: "assets",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/Assets.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/update-assets",
    name: "updateAssets",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UpdateAssets.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/create-assets",
    name: "createAssets",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/CreateAssets.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/deposit-transactions",
    name: "depositTransactions",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/DepositTransactions.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/user-assets",
    name: "userAssets",
    component: () =>

      import(/* webpackChunkName: "terminate" */ "../views/UserAssets.vue"),
      
    meta: { authOnly: true },
  },


  {
    path: "/create-block-deal",
    name: "createBlockDeal",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/CreateBlockDeal.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/update-block-deal/:deal_id",
    name: "updateBlockDeal",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UpdateBlockDeal.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/members/:member?",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/MembersView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/level-members",
    name: "LevelMembers",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/members/LevelMembers.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/direct-members",
    name: "DirectMembers",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/members/DirectMembers.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/generation",
    name: "GenerationTree",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/members/GenerationTree.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/user-setting",
    name: "userSetting",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UserSetting.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/block-deal",
    name: "blockDeal",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/BlockDeal.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/translate-page",
    name: "translate-page",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TranslatePage.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/topup-history",
    name: "TopUpHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TopUpHistory.vue"),
    meta: { authOnly: true },
  },

  // Raman
  {
    path: "/users",
    name: "UsersView",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UsersView.vue"),
    meta: { authOnly: true },
  },



];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // This is a navigation guard that runs before each route change

  // Check if the user is logged in by looking for a token in localStorage
  const loggedIn = localStorage.getItem("token");

  // Check if the route requires authentication
  const isAuth = to.matched.some((record) => record.meta.authOnly);

  // If the route is the root path ("/"), allow navigation
  if (to.fullPath == "/") {
    return next();
  }

  // If the route requires auth and user is not logged in, redirect to login page
  if (isAuth && !loggedIn) {    
    return next({ path: "/login" });
  } 
  // If the route doesn't require auth and user is logged in, redirect to dashboard
  else if (!isAuth && loggedIn) {
    return next({ path: "/dashboard" });
  } 
  // If the route doesn't require auth and user is not logged in, allow navigation
  else if (!isAuth && !loggedIn) {
    return next();
  }

  // If none of the above conditions are met, allow navigation
  next();
});

export default router;
